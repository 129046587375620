import mainjson from '../config/main';
export default function GetData() {
        return {
        "apitype":"usergroups",
        "id":"usergroup_id",
        "subidname":"usergroup_name",
        "options":["edit","insert","delete"],
        "nameSingle":"user group",
        "nameMultiple":"user groups",
        "filters":
            [
               
            ]
        ,
        "fields":{
            "field1":{
                "name":"Name",
                "field":"usergroup_name",
                "type":"TextInput",
                "required":true,
                "list":true,
            },
            "field2":{
                "name":"State",
                "field":"usergroup_active",
                "type":"DropDown",
                "options": {
                    "0":"inactive",
                    "1":"active"
                },
                "required":true,
                "list":true 

            },   
        }
    }
}